<template>
  <div class="h-full w-full flex flex-col overflow-x-auto">
    <div class="flex justify-center items-center">
      <div class="w-screen bg-white shadow-lg border-t">
        <div class="flex flex-col py-8 border-b border-gray-500 head">
          <h1 class="pt-1 text-xl font-bold text-center text-blue-active">
            Fiche Client
          </h1>
          <div class="pt-5 text-center text-md text-ink-l2">
            <div class="">
              <div class="flex justify-center items-center px-20">
                <v-input
                  v-on:keyup.enter="search(searchFiche)"
                  v-model="searchFiche"
                  left-icon="search"
                  style=""
                  placeholder="Entrez le numéro de téléphone, le nom ou le prénom du bénéficiaire"
                  class="w-full "
                />
                <button
                  style="padding: 13px 20px ; background-color:#51AA1C"
                  @click="search(searchFiche)"
                  class="
                    focus:outline-none

                    font-bold
                    text-white
                    flex
                    justify-center
                    rounded-md
                    -ml-1
                    rounded-l-none
                  "
                >
                  Rechecher
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="p-6 space-y-6 flex-grow "
      v-if="getBeneficiaries.length >= 0 && loading == 0"
    >
      <div v-if="firstLoad == 1">
        <span> {{ getBeneficiaries.length }} résultat(s) pour </span>
        <span class="underline"> {{ query }}</span>
      </div>
      <div class="grid grid-cols-1   gap-6  lg:grid-cols-3  ">
        <div
          class="cursor-pointer "
          v-for="item in getBeneficiaries"
          :key="item.id"
          @click="ficheDetails(item)"
        >
          <div
            class="
              bg-white
              rounded-md
              flex
              space-x-4
              justify-start

              shadow-xl
              p-4
            "
            style="width:325px; height:140px"
          >
            <div class="rounded-full h-12 w-12  bg-red-200 flex-shrink-0">
              <img src="" class="rounded-full w-full" alt="" />
            </div>
            <div class="w-full">
              <div class="font-bold truncate text-xs w-48">
                {{ item.beneficiary ? item.beneficiary.name : '---' }}
              </div>
              <div class="text-xs space-y-2">
                <span>
                  {{ item.beneficiary ? item.beneficiary.phone : '---' }}
                </span>
                <span v-if="item.otmInfos.provider == 'mtn-benin'">(MTN)</span
                ><span v-else>(MOOV)</span><br />
                <span class="">
                  <div v-if="item.creditInProgress != false">
                    <span>1 crédit en cours -</span>
                    <span class="" style="font-size:10px">
                      Chez
                      {{
                        item.creditInProgress
                          ? item.creditInProgress.creditOwner
                          : '--'
                      }}</span
                    >
                  </div>
                  <span v-else> 0 crédit en cours </span>
                </span>

                <div class="">
                  <a
                    @click="ficheDetails(item)"
                    class="
                      cursor-pointer
                      text-blue-active
                      rounded-full
                      border-2
                      font-bold
                      w-40
                      p-1
                      border-blue-active
                      flex
                      justify-center
                    "
                    >Voir la fiche client
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-grow flex items-center justify-center">
      <div class="loader" v-if="loading == 1"></div>
      <div
        v-else-if="loading == 2"
        class="space-x-8 flex bg-red-100 border border-danger text-red-700 p-4 rounded relative"
      >
        <div>
          <span class="block sm:inline text-danger"
            >Erreur de connexion. Veuillez réessayer !</span
          >
        </div>
        <div>
          <a @click="close" class="cursor-pointer">
            <svg
              class="w-6 h-6"
              fill="red"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
        </div>
      </div>

      <div
        class="flex justify-center items-center py-10"
        v-if="getBeneficiaries.length == 0 && !loading"
      >
        <img src="@/assets/img/Group.png" alt="seach" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      searchFiche: '',
      Beneficiaries: null,
      loading: Boolean,
      firstLoad: 0,
      query: ''
    }
  },
  computed: {
    ...mapGetters('ficheClient', ['getBeneficiaries', 'getAll'])
  },
  methods: {
    ...mapActions('ficheClient', [
      'searchBeneficiaries',
      'showDetails',
      'allBeneficiaries'
    ]),
    ficheDetails(element) {
      this.showDetails(element)
      this.$router.push({
        name: 'DetailsFiche',
        params: { id: `${element.beneficiary.id}` }
      })

      console.log(element)
    },
    close() {
      console.log('hh')
      this.loading = 0
    },
    async search(data) {
      this.loading = 1
      this.firstLoad = 0

      this.searchBeneficiaries(data)
        .then(() => {
          this.loading = 0
          this.query = data
          this.firstLoad = 1
        })
        .catch(error => {
          this.loading = 2
          console.error(error)
        })

      console.log(data)
    }
  },
  async mounted() {
    await this.searchBeneficiaries()
    await this.allBeneficiaries('')
    this.loading = 0
  }
}
</script>
<style scoped>
.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #149e36; /* green */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
input {
  border-radius: 50px !important;
}
</style>
